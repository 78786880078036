import { useQuery } from '@tanstack/vue-query'

import type { Tables } from '~/supabase.db'

export function useRandomRegions(limit: MaybeRef<number>) {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['region-showcase', limit] as const,
    queryFn: async ({ queryKey: [_, pick] }) =>
      supabase
        .rpc('get_random_region', {
          pick,
        })
        .returns<Tables<'region'>[]>()
        .throwOnError()
        .then((res) => res.data),
  })
}

export function useRandomOffersByLocation(
  locationSlugId: MaybeRefOrGetter<string>,
  limit: MaybeRef<number>,
) {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['offer-showcase-by-location', toRef(locationSlugId), limit] as const,
    queryFn: async ({ queryKey: [_, slugId, pick] }) =>
      supabase
        .rpc('get_random_offer_by_location_slug_id', {
          input_slug_id: slugId,
          pick,
        })
        .returns<Tables<'offer'>[]>()
        .throwOnError()
        .then((res) => res.data),
  })
}

export function useRandomOffersByArtist(
  artistSlugId: MaybeRefOrGetter<string>,
  limit: MaybeRef<number>,
) {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['offer-showcase-by-artist', toRef(artistSlugId), limit] as const,
    queryFn: async ({ queryKey: [_, slugId, pick] }) =>
      supabase
        .rpc('get_random_offer_by_artist_slug_id', {
          input_slug_id: slugId,
          pick,
        })
        .returns<Tables<'offer'>[]>()
        .throwOnError()
        .then((res) => res.data),
  })
}

export function useRandomOffers(limit: MaybeRef<number>) {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['offer-showcase', limit] as const,
    queryFn: async ({ queryKey: [_, pick] }) =>
      supabase
        .rpc('get_random_offer', {
          pick,
        })
        .returns<Tables<'offer'>[]>()
        .throwOnError()
        .then((res) => res.data),
  })
}

export function useRandomLocations(limit: MaybeRef<number>) {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['location-showcase', limit] as const,
    queryFn: async ({ queryKey: [_, pick] }) =>
      supabase
        .rpc('get_random_location', {
          pick,
        })
        .returns<(Tables<'location'> & { offer_count: number })[]>()
        .throwOnError()
        .then((res) => res.data),
  })
}

export function useRandomArtists(limit: MaybeRef<number>) {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['artist-showcase', limit] as const,
    queryFn: async ({ queryKey: [_, pick] }) =>
      supabase
        .rpc('get_random_artists', {
          pick,
        })
        .returns<(Tables<'artist'> & { offer_count: number })[]>()
        .throwOnError()
        .then((res) => res.data),
  })
}
